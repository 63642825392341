/**
 * This code was generated by Builder.io.
 */
import React, {useEffect} from "react";
import styles from "./TellerATM.module.css";
import OptionCard from "./OptionCard";
import ReturnButton from "./ReturnButton";
import {Widget as TellerWidget} from "@teller-protocol/teller-widget";


import withdrawIcon from "../icons/withdraw.png";
import depositIcon from "../icons/deposit.png";
import loanIcon from "../icons/teller.png";
import ammoIcon from "../icons/ammo.png";
import healthIcon from "../icons/health.png";

const whiteListedTokens = {
    [8453]: ["0xB9a3F938a462B94c3cEe84eEFD4fd764fFd1254B"]
};

const TellerATM = () => {
    const options = [{
        title: "WITHDRAW", icon: withdrawIcon, status: "COMING AUGUST",
    }, {
        title: "DEPOSIT", icon: depositIcon, status: "COMING AUGUST",
    }, {
        title: "GET LOAN", icon: loanIcon, status: "BY TELLER", isSpecial: true,
    }, {
        title: "AMMO", icon: ammoIcon, status: "PACK", isSpecial: true,
    }, {
        title: "HEALTH", icon: healthIcon, status: "PACK", isSpecial: true,
    }];

    return (<main className={styles.container}>
            <ReturnButton/>
            <img
                loading="lazy"
                src={loanIcon}
                alt="Teller ATM Logo"
                className={styles.logo}
            />
            <div className={styles.contentWrapper}>
                <h1 className={styles.welcomeText}>WELCOME TO THE TELLER ATM</h1>
                <p className={styles.questionText}>WHAT DO YOU WANT TO DO TODAY?</p>
            </div>
            <section className={styles.optionsWrapper}>
                {options.map((option, index) => (
                    <div key={index} className={styles.optionCardWrapper}>
                        <OptionCard
                            title={option.title}
                            icon={option.icon}
                            status={option.status}
                            isSpecial={option.isSpecial}
                        />
                        {option.title === "GET LOAN" && (
                            <TellerWidget buttonLabel={""} buttonClassName={styles.overlapButton} isBareButton={true} whitelistedTokens={whiteListedTokens}>Invisible Button</TellerWidget>
                        )}
                    </div>
                ))}
            </section>
        </main>);
};

export default TellerATM;
